<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="info"
  >
    <div class="row" v-if="item != undefined">
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          v-model="obs"
          label="Observación"
          placeholder="Observación"
          :disabled="true"
        ></CInput>
      </CCol>
      <CCol lg="6" md="6" sm="12" xs="12">
        <CInput
          v-model="enc"
          label="Encargado"
          placeholder="Encargado"
          :disabled="true"
        ></CInput>
      </CCol>
      <CCol lg="6" md="6" sm="12" xs="12">
        <CInput
          v-model="coti"
          label="Cotización"
          placeholder="Cotización"
          :disabled="true"
        ></CInput>
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="position-relative table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Producto</th>
              <th>Unidad</th>
              <th>Proveedor</th>
              <th>Cantidad</th>
              <th>Costo</th>
              <th>Costo S/I</th>
              <th>%</th>
              <th>Pre. Venta</th>
              <th>Venta</th>
              <th>Costo</th>
              <th>Ganancia</th>
            </tr>
          </thead>
          <tbody v-if="item.length > 0">
            <tr v-for="(it, index) in item" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ it.description }}</td>
              <td>{{ it.unit }}</td>
              <td>{{ it.proveedor }}</td>
              <td>{{ it.quantity }}</td>
              <td>{{ it.costo }}</td>
              <td>{{ it.cost_sin_igv }}</td>
              <td>{{ it.porcentaje }}</td>
              <td>{{ it.price_venta }}</td>
              <td>{{ parseFloat(it.subtotal_venta).toFixed(2) }}</td>
              <td>{{ parseFloat(it.subtotal_costo).toFixed(2) }}</td>
              <td>{{ parseFloat(it.subtotal_ganancia).toFixed(2) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="6">
                <slot name="no-items-view">
                  <div class="text-center">
                    <h2>No hay registros para mostrar</h2>
                  </div>
                </slot>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="text-right" colspan="9">SUBTOTAL</th>
              <th>{{cp_total_venta}}</th>
              <th>{{cp_total_costo}}</th>
              <th>{{cp_total_ganancia}}</th>
            </tr>
            <tr>
              <th class="text-right" colspan="9">IGV</th>
              <th>{{this.totales.venta ==0?0: parseFloat(this.totales.venta *0.18).toFixed(2) }}</th>
              <th>{{this.totales.costo ==0?0: parseFloat(this.totales.costo *0.18).toFixed(2) }}</th>
              <th>{{this.totales.ganancia ==0?0: parseFloat(this.totales.ganancia *0.18).toFixed(2) }}</th>
            </tr>
            <tr>
              <th class="text-right" colspan="9">TOTAL</th>
              <th>{{this.totales.venta ==0?0: parseFloat(this.totales.venta *1.18).toFixed(2) }}</th>
              <th>{{this.totales.costo ==0?0: parseFloat(this.totales.costo *1.18).toFixed(2) }}</th>
              <th>{{this.totales.ganancia ==0?0: parseFloat(this.totales.ganancia *1.18).toFixed(2) }}</th>
            </tr>
          </tfoot>
        </table>
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "c-modal-view",
  data() {
    return {
      totales:{
        venta:0,
        costo:0,
        ganancia:0
      }
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    obs:String,
    enc:String,
    coti:String,
    size: String,
  },
  computed: {
    cp_total_venta(){
      let res = 0;
      this.item.forEach(element => {
        res = parseFloat(res) + parseFloat(element.subtotal_venta);
      });
      this.totales.venta= parseFloat(res).toFixed(2);
      return this.totales.venta;
    },
    cp_total_costo(){
      let res = 0;
      this.item.forEach(element => {
        res = parseFloat(res) + parseFloat(element.subtotal_costo);
      });
      this.totales.costo= parseFloat(res).toFixed(2);
      return this.totales.costo;
    },
    cp_total_ganancia(){
      let res = 0;
      this.item.forEach(element => {
        res = parseFloat(res) + parseFloat(element.subtotal_ganancia);
      });
      this.totales.ganancia= parseFloat(res).toFixed(2);
      return this.totales.ganancia;
    },
  },
  methods: {
    mtd_close() {
      this.$emit("close_view");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>